import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import FieldText from '../../generic/FieldText';
import PhoneInputElem from '../../../../components/PhoneInputElem';
import Button from '../../../../generic/Button';
import getCarbonFiles from '../../../../services/getCarbonFiles';
import downloadFileCarbon from '../../../../services/downloadFileCarbon';

const ContactInfo = ({ step, data, setStep, projectData }) => {
  const { id } = useParams();
  const [filesListTenure, setFilesListTenure] = useState([]);
  const [filesListOthers, setFilesListOthers] = useState([]);
  useEffect(() => {
    (async () => {
      const list = await getCarbonFiles(id) ?? [];
      if (list.length) {
        const genList = [];
        list.forEach((el) => {
          if (el?.file_type === 'general') genList.push({ path: el.filename, id: el.id });
        });
        const otherList = [];
        list.forEach((el) => {
          if (el?.file_type === 'other') otherList.push({ path: el.filename, id: el.id });
        });

        if (genList.length) setFilesListTenure(genList);
        if (otherList.length)setFilesListOthers(otherList);
      }
    })();
  }, []);
  const history = useHistory();
  if (step !== 6) {
    return null;
  }
  const {
    comments = '',
    forestMonitoring = {
      email: '',
      organisation: '',
      personName: '',
      phoneNumber: '',
    },
    projectManagement = {
      email: '',
      organisation: '',
      personName: '',
      phoneNumber: '',
    },
    treePlanting = {
      email: '',
      organisation: '',
      personName: '',
      phoneNumber: '',
    },
  } = data?.carbonFormPrivateInfo?.fields;
  const downloadFile = async (file) => {
    const response = await downloadFileCarbon({ file_id: file.id });
    if (response?.status === 200) {
      const downloadUrl = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = file.path;
      link.click();
      link.remove();
    }
  };
  const approveOrDenied = projectData?.leakage;
  const getList = (arr) => (
    <ul className="eval__files">
      {arr.map((item, index) => (
        <li key={`${item.path}${index + Date.now()}`}>
          <span onClick={() => downloadFile(item)}>
            {index + 1}
            .
            {' '}
            {item.path}
          </span>
        </li>
      ))}
    </ul>
  );
  return (
    <div className="eval__block">
      <div className="eval__block">
        <span className="eval__activity-title">
          6.1 Land tenure or convention documents
        </span>
        {getList(filesListTenure)}
      </div>
      <div className="eval__block">
        <span className="eval__activity-title">
          6.2 Other documents
        </span>
        {getList(filesListOthers)}
      </div>
      <div className="eval__block">
        <span className="eval__activity-title">6.3 Comments</span>

        <span className="eval__activity-subtitle">Please provide a brief explanation of why land tenure documentation cannot be provided at this time.</span>
        <FieldText
          value={data?.carbonFormPrivateInfo?.documentationComment}
          customClass="full not-out full not-out eval__activity-field textarea"
        />
      </div>
      <div className="eval__block">
        <span className="eval__activity-title">
          7.1 Who is responsible for the project management?
        </span>
        <div className="eval__activity-block">
          <FieldText
            label="Organisation name"
            value={projectManagement?.organisation}
            customClass="half eval__activity-field"
            labelClass="eval__field-subLabel"
          />
          <FieldText
            label="Email"
            value={projectManagement?.email}
            customClass="half eval__activity-field"
            labelClass="eval__field-subLabel"
          />
          <FieldText
            label="Contact person name"
            value={projectManagement?.personName}
            customClass="half eval__activity-field"
            labelClass="eval__field-subLabel"
          />
          <div className="eval__wrapper-phone">
            <PhoneInputElem
              value={projectManagement?.phoneNumber}
              label="Telephone"
              disabled
            />
          </div>
        </div>
      </div>
      <div className="eval__block">
        <span className="eval__activity-title">
          7.2 Who is/will be responsible for the tree planting activities?
        </span>
        <div className="eval__activity-block">
          <FieldText
            label="Organisation name"
            value={treePlanting?.organisation}
            customClass="half eval__activity-field"
            labelClass="eval__field-subLabel"
          />
          <FieldText
            label="Email"
            value={treePlanting?.email}
            customClass="half eval__activity-field"
            labelClass="eval__field-subLabel"
          />
          <FieldText
            label="Contact person name"
            value={treePlanting?.personName}
            customClass="half eval__activity-field"
            labelClass="eval__field-subLabel"
          />
          <div className="eval__wrapper-phone">
            <PhoneInputElem
              value={treePlanting?.phoneNumber}
              label="Telephone"
              disabled
            />
          </div>
        </div>
      </div>
      <div className="eval__block">
        <span className="eval__activity-title">
          7.3 Who is/will be responsible for the forest monitoring?
        </span>
        <div className="eval__activity-block">
          <FieldText
            label="Organisation name"
            value={forestMonitoring?.organisation}
            customClass="half eval__activity-field"
            labelClass="eval__field-subLabel"
          />
          <FieldText
            label="Email"
            value={forestMonitoring?.email}
            customClass="half eval__activity-field"
            labelClass="eval__field-subLabel"
          />
          <FieldText
            label="Contact person name"
            value={forestMonitoring?.personName}
            customClass="half eval__activity-field"
            labelClass="eval__field-subLabel"
          />
          <div className="eval__wrapper-phone">
            <PhoneInputElem
              value={forestMonitoring?.phoneNumber}
              label="Telephone"
              disabled
            />
          </div>
        </div>
      </div>
      <div className="eval__block">
        <span className="eval__activity-title">Comments</span>
        <FieldText
          value={comments}
          customClass="full not-out full not-out eval__activity-field textarea"
        />
      </div>
      <div className="eval__wrapper-btns">
        <Button className="cancel" onClick={() => setStep(5)}>Back </Button>
        {!approveOrDenied && <Button onClick={() => history.push(`/leakage/${id}`)}>Next</Button>}
      </div>
    </div>
  );
};

export default ContactInfo;
